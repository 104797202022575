export type TLog = {
  type: 'Success' | 'Failure'
  ip: string
  timestamp: string
}

export function makeLog(array: string[] = [], type: TLog['type'] = 'Success') {
  return array.map((data: string) => ({
    type,
    timestamp: data.split(',')[0],
    ip: data.split(',')[1]
  }))
}

export function sortLog(logs: TLog[] = []) {
  return logs.sort((x, y) => y.timestamp.localeCompare(x.timestamp))
}
