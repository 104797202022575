import { TextField, TextFieldPassword } from '@components'
import useForm, { ValidationRule } from '@utils/hooks/useForm'
import { TUserConfig } from '@utils/api'
import { arrayFromCommaString } from '@utils/misc'
import React, { ReactElement } from 'react'
import { Stack } from '@mui/material'

export type TFormUser = TUserConfig

interface Props {
  form: ReturnType<typeof useForm>
  includePassword?: boolean
  includeRole?: boolean
  includeUsername?: boolean
  disableUsername?: boolean
}

export const initialValues: TFormUser = {
  full_name: '',
  username: '',
  email: '',
  roles: ['admin'],
  password: ''
}

export const initialValidation: ValidationRule<TFormUser> = {
  full_name: (value) => !!value && value.length > 0,
  username: (value) => value.length > 0,
  email: (value) => !!value && value.length > 0,
  roles: (value) => !!value && value.length > 0,
  password: (value) => !!value && value.length > 0
}

export function FormUser({
  form,
  includePassword = true,
  includeRole = true,
  includeUsername = true,
  disableUsername = false
}: Props): ReactElement {
  return (
    <Stack spacing={3}>
      <TextField required id="full_name" label="Full Name" form={form} />

      {includeUsername && (
        <TextField
          required
          id="username"
          label="Username"
          form={form}
          disabled={disableUsername}
          inputProps={disableUsername ? { readOnly: true } : {}}
        />
      )}

      <TextField required id="email" label="Email" form={form} />

      {includeRole && (
        <TextField
          required
          id="roles"
          label="Roles"
          disabled
          inputProps={{ readOnly: true }}
          form={form}
          value={form.values.roles.join(',')}
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) =>
            form.setFieldValue(
              'roles',
              arrayFromCommaString(event.currentTarget.value)
            )
          }
        />
      )}

      {includePassword && (
        <TextFieldPassword
          required
          id="password"
          label="Password"
          form={form}
        />
      )}
    </Stack>
  )
}

export function validateAddCredentials(form: ReturnType<typeof useForm>) {
  return form.validateFields([
    'name',
    'username',
    'auth_type',
    'ssh_key_b64',
    'password'
  ])
}
