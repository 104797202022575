import React, { FC, useEffect, useState } from 'react'
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography,
  useTheme
} from '@mui/material'
import { getUserData } from '@utils/auth'
import { TextField, Card, TextFieldPassword } from '@components'
import {
  initialValidation,
  TFormUser,
  initialValues as initialUserValues,
  FormUser
} from '@components/Form/FormUser'
import useForm from '@hooks/useForm'
import { Beenhere, Block } from '@mui/icons-material'
import moment from 'moment'
import { Button } from 'gatsby-material-ui-components'
import GenericDetailPage from '@templates/Generic/Detail'
import { makeLog, sortLog } from '@utils/logs'
import {
  ApiResponse,
  getUser,
  TUserConfig,
  updateUser,
  User,
  UserResponse
} from '@utils/api'
import useApi, { TUseApiHook, wasSuccess } from '@hooks/useApi'
import { Skeleton } from '@mui/material'
import { useContext } from 'react'
import AuthContext from '@contexts/AuthContext'

delete initialUserValues.roles
delete initialUserValues.password

const MyAccount = () => {
  const { username } = getUserData()
  const { user } = useContext(AuthContext)

  const api = useApi<UserResponse>({
    apiMethod: getUser,
    params: username
  })

  const [userData, setUserData] = useState<User | {}>(user || {})

  useEffect(() => {
    if (api.response) setUserData(api.response)
  }, [api.response])

  return (
    <GenericDetailPage
      title="My Account"
      tabNames={[
        'Account Summary',
        'Change Details',
        'Change Password',
        'Security Logs'
      ]}
      tabContents={(setCurrentTab) => [
        <MyAccountSummary
          api={api}
          userData={userData}
          setCurrentTab={setCurrentTab}
        />,
        <MyAccountDetails api={api} userData={userData} />,
        <MyAccountPassword api={api} userData={userData} />,
        <MyAccountLog api={api} userData={userData} />
      ]}
      breadcrumbs={[
        {
          title: 'My Account'
        }
      ]}
    ></GenericDetailPage>
  )
}

type TabProps = {
  setCurrentTab?: React.Dispatch<React.SetStateAction<number>>
  api: TUseApiHook<UserResponse>
  userData: User | null
}

const MyAccountSummary: FC<TabProps> = ({ setCurrentTab, api, userData }) => {
  const { username = '', email = '', full_name = '', roles = '' } = userData
  const successLog = makeLog(userData.logins)
  const failedLog = makeLog(userData.logins_failed, 'Failure')
  const log = sortLog([...successLog, ...failedLog])
  const { spacing } = useTheme()
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={4}>
        <Card heading="My Details" sx={{ height: '100%' }}>
          <Box p={1}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                {!api.loading && api.response ? (
                  <>
                    <Avatar
                      sx={{
                        m: '0 auto',
                        mb: 1,
                        fontSize: '3rem',
                        width: (theme) => theme.spacing(10),
                        height: (theme) => theme.spacing(10),
                        bgcolor: 'primary.main',
                        color: 'common.white'
                      }}
                    >
                      {username.charAt(0).toUpperCase() || ''}
                    </Avatar>
                    <Typography
                      variant="subtitle1"
                      align="center"
                    >{`${full_name} (${username})`}</Typography>

                    <Typography variant="body2" align="center">
                      <strong>Created: </strong>
                      {` ${moment(userData.created_date).format(
                        'MMMM Do YYYY'
                      )}`}
                    </Typography>
                    <Typography variant="body2" align="center">
                      <strong>Last Used: </strong>
                      {` ${moment(userData.last_login_date).format(
                        'MMMM Do YYYY'
                      )}`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="circular"
                      height={spacing(10)}
                      width={spacing(10)}
                    />
                    <Skeleton height="2rem" />
                    <Skeleton />
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Card
          heading="My Profile"
          headingButton={
            <Button
              size="small"
              variant="outlined"
              onClick={() => setCurrentTab && setCurrentTab(1)}
            >
              Edit Details
            </Button>
          }
        >
          <Box p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled
                  id="full_name"
                  label="Full Name"
                  value={full_name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="username"
                  label="Username"
                  disabled
                  inputProps={{ readOnly: true }}
                  value={username}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField disabled id="email" label="Email" value={email} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="roles"
                  label="Roles"
                  disabled
                  inputProps={{ readOnly: true }}
                  value={roles ? roles.join(',') : ''}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          heading="Recent Login Activity (last 5)"
          headingButton={
            <Button size="small" variant="outlined">
              See Full Log
            </Button>
          }
        >
          <List>
            {!api.loading && api.response ? (
              log.slice(0, 5).map(({ type, timestamp, ip }) => (
                <ListItem key={`${type}-${ip}-${timestamp}`}>
                  <ListItemIcon>
                    {type === 'Success' ? (
                      <Beenhere color="primary" />
                    ) : (
                      <Block color="error" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${type} - ${ip}`}
                    secondary={`${timestamp}`}
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="caption">
                      <em>{moment(timestamp).fromNow()}</em>
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemIcon>
                  <Skeleton variant="circular" />
                </ListItemIcon>
                <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
                <ListItemSecondaryAction>
                  <Skeleton />
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </Card>
      </Grid>
    </Grid>
  )
}

const MyAccountPassword: FC<TabProps> = ({ userData, api }) => {
  const updateApi = useApi<ApiResponse<string>>({
    apiMethod: updateUser,
    requestOnMount: false
  })

  const initialValues = {
    username: userData ? userData.username : '',
    password: '',
    reenter: ''
  }

  const passwordForm = useForm<TFormUser>({
    initialValues,
    initialValidationRules: {
      password: (value) => !!value && value.length > 11,
      reenter: (value, { password }) => !!value && value === password
    }
  })

  const handleUpdate = (values: TUserConfig) => {
    console.log('values', values)

    updateApi.makeRequest(values).then((response) => {
      if (wasSuccess(response)) {
        updateApi.enqueueSnackbar('Password successfully updated.', {
          variant: 'success'
        })
        userData && api.makeRequest(userData.username)
        passwordForm.reset()
      }
    })
  }

  const hasChanged =
    JSON.stringify(initialValues) !== JSON.stringify(passwordForm.values)

  return (
    <form
      noValidate
      onSubmit={passwordForm.onSubmit((values) => handleUpdate(values))}
    >
      <Card heading="Change My Password" style={{ height: 'auto' }}>
        <Stack p={3} spacing={3}>
          <TextFieldPassword
            id="password"
            label="Password"
            form={passwordForm}
            required
            helperText="Password needs to be at least 12 characters long"
          />
          <TextFieldPassword
            id="reenter"
            label="Re-enter Password"
            form={passwordForm}
            required
            helperText="Passwords must match"
          />
        </Stack>
      </Card>
      <Box pt={2}>
        <Button
          type="submit"
          size="large"
          disabled={
            !hasChanged ||
            passwordForm.errors.password || passwordForm.errors.reenter
          }
          variant="contained"
          color="primary"
        >
          Update Password
        </Button>
      </Box>
    </form>
  )
}

const MyAccountDetails: FC<TabProps> = ({ api, userData = {} }) => {
  const [saved, setSaved] = useState<boolean>(false)

  const updateApi = useApi<ApiResponse<string>>({
    apiMethod: updateUser,
    requestOnMount: false
  })

  const initialValues = {
    ...initialUserValues,
    email: userData ? userData.email : '',
    username: userData ? userData.username : '',
    full_name: userData ? userData.full_name : ''
  }

  const userForm = useForm<TFormUser>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const handleUpdate = (values: TUserConfig) => {
    updateApi.makeRequest(values).then((response) => {
      if (wasSuccess(response)) {
        updateApi.enqueueSnackbar('Account details successfully updated.', {
          variant: 'success'
        })
        setSaved(true)

        userData && api.makeRequest(userData.username)
      }
    })
  }

  const hasChanged =
    JSON.stringify(initialValues) !== JSON.stringify(userForm.values)

  return (
    <form
      noValidate
      onSubmit={userForm.onSubmit((values) => handleUpdate(values))}
    >
      <Card heading="Change My Details">
        <Stack p={3} spacing={3}>
          <FormUser
            form={userForm as any}
            includePassword={false}
            includeRole={false}
            includeUsername={false}
          />
        </Stack>
      </Card>
      <Box pt={2}>
        <Button
          type="submit"
          size="large"
          disabled={!hasChanged}
          variant="contained"
          color="primary"
        >
          Update Details
        </Button>
      </Box>
    </form>
  )
}

const MyAccountLog: FC<TabProps> = ({ userData }) => {
  const successLog = userData ? makeLog(userData.logins) : []
  const failedLog = userData ? makeLog(userData.logins_failed, 'Failure') : []
  const log = sortLog([...successLog, ...failedLog])
  return (
    <Box>
      <Card heading="Recent Login Attempts">
        <List>
          {log.map(({ type, timestamp, ip }) => (
            <ListItem key={`${type}-${ip}-${timestamp}`}>
              <ListItemIcon>
                {type === 'Success' ? (
                  <Beenhere color="primary" />
                ) : (
                  <Block color="error" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`${type} - ${ip}`}
                secondary={`${timestamp}`}
              />
              <ListItemSecondaryAction>
                <Typography variant="caption">
                  <em>{moment(timestamp).fromNow()}</em>
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Card>
    </Box>
  )
}

export default MyAccount
